<template>
  <svg
    :width="this.width"
    :height="this.height"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M25 49C38.2548 49 49 38.2548 49 25C49 11.7452 38.2548 1 25 1C11.7452 1 1 11.7452 1 25C1 38.2548 11.7452 49 25 49Z"
        :stroke="this.iconColor"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M29.933 35.528C29.787 33.916 29.843 32.791 29.843 31.318C30.573 30.935 31.881 28.493 32.102 26.43C32.676 26.383 33.581 25.823 33.846 23.612C33.989 22.425 33.421 21.757 33.075 21.547C34.009 18.738 35.949 10.048 29.487 9.15C28.822 7.982 27.119 7.391 24.906 7.391C16.052 7.554 14.984 14.077 16.925 21.547C16.58 21.757 16.012 22.425 16.154 23.612C16.42 25.823 17.324 26.383 17.898 26.43C18.118 28.492 19.478 30.935 20.21 31.318C20.21 32.791 20.265 33.916 20.119 35.528C18.858 38.918 12.382 39.183 8.646 42.452C12.552 46.385 18.882 49.198 25.562 49.198C32.242 49.198 40.094 43.924 41.401 42.485C37.688 39.186 31.197 38.93 29.933 35.528Z"
        :stroke="this.iconColor"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="50" height="50" :fill="this.iconColor" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "box"
    },
    width: {
      type: [Number, String],
      default: 50
    },
    height: {
      type: [Number, String],
      default: 50
    },
    iconColor: {
      type: String,
      default: "white"
    }
  }
};
</script>
<style></style>
