import Layout from "@/layout/Layout.vue";
import AuthRequired from "../helpers/AuthRequired";

const routes = [
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "home",
        name: "Home",
        component: () => import("../views/Home.vue")
      },
      {
        path: "dashboard",
        name: "Dashboard",
        component: () => import("../views/Dashboard.vue")
      },
      {
        path: "informacoes",
        name: "Informacoes",
        component: () => import("../views/Informacoes.vue")
      },
      {
        path: "utilidades",
        name: "Utilidades",
        component: () => import("../views/Utilidades.vue")
      },
      {
        path: "acesso",
        name: "Acessar",
        component: () => import("../views/Acessar.vue")
      },
      {
        path: "cidade/:id",
        name: "Cidade-ver",
        component: () => import("../views/Painel.vue")
      },
      {
        path: "registrarCaso",
        name: "Cidade-cadastrar",
        component: () => import("../views/RegisterCase.vue")
      },
      {
        path: "editarCaso/:id",
        name: "Cidade-editar",
        component: () => import("../views/RegisterCase.vue")
      }
    ]
  },
  {
    path: "/painel",
    beforeEnter: AuthRequired,
    component: Layout,
    redirect: "/painel/gestor",
    children: [
      {
        path: "gestor",
        name: "Gestor",
        component: () => import("../components/Dashboard/Dashboard.vue")
      }
    ]
  },
  {
    path: "*",
    redirect: "/"
  }
];

export default routes;
