import axios from "axios";
import { Auth } from "./oauth";
import envInfo from "../../env.json";
const request = axios.create({
  baseURL: envInfo.environments[envInfo.current_env].api_url
});
class Service {
  constructor() {}
  get(path = "", params = {}) {
    return new Promise((resolve, reject) => {
      request
        .get(path, {
          params: params,
          headers: { Authorization: `Bearer ${Auth.getToken()}` }
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response.data);
        });
    });
  }
  post(path = "", object) {
    return new Promise((resolve, reject) => {
      request
        .post(path, object, {
          headers: { Authorization: `Bearer ${Auth.getToken()}` }
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response.data);
        });
    });
  }
  put(path = "", object) {
    return new Promise((resolve, reject) => {
      request
        .put(path, object, {
          headers: { Authorization: `Bearer ${Auth.getToken()}` }
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response.data);
        });
    });
  }
  delete(path = "", id) {
    return new Promise((resolve, reject) => {
      request
        .delete(`${path}/${id}`, {
          headers: { Authorization: `Bearer ${Auth.getToken()}` }
        })
        .then(
          response => {
            resolve(response.data);
          },
          error => {
            reject(error);
          }
        );
    });
  }
}
export default Service;
