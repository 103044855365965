<template>
  <div>
    <b-navbar fixed="top" toggleable="lg" type="dark" variant="primary">
      <router-link to="/">
        <img src="@/assets/logo.svg" alt="Minha Figura" />
        <img class="fontImage" src="@/assets/font.svg" alt="Minha Figura" />
      </router-link>
      <button
        type="button"
        aria-label="Toggle navigation"
        aria-controls="nav-collapse"
        aria-expanded="false"
        class="navbar-toggler"
        @click="$sidebar.displaySidebar(!$sidebar.showSidebar)"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item @click="logoff" right v-if="isLogged">
            <div class="d-flex align-items-start">
              <a style="color: #fff; padding-right: 10px; padding-top: 5px;"
                >Sair</a
              >
              <img height="30px" :src="require('../assets/icon/logout.svg')" />
            </div>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      data: [
        {
          label: "Home",
          router: "/home"
        },
        {
          label: "Informações",
          router: "/informacoes"
        },
        {
          label: "Utilidades",
          router: "/utilidades"
        },
        {
          label: "Acessar",
          router: "/acesso"
        }
      ]
    };
  },
  methods: {
    ...mapActions(["logOut"]),
    goTo(item) {
      this.$router.push(item.router);
    },
    logoff() {
      this.logOut();
      this.$router.push({ path: "/acesso" });
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
    isLogged() {
      return this.currentUser != null;
    }
  }
};
</script>

<style scoped>
span {
  color: #fff;
}
@media (max-width: 480px) {
  .fontImage {
    display: none;
  }
}
</style>
