export default (to, from, next) => {
  if (
    localStorage.getItem("user-payload") != null &&
    localStorage.getItem("user-payload").length > 0
  ) {
    next();
  } else {
    localStorage.removeItem("user-payload");
    localStorage.removeItem("token");
    next("/acesso");
  }
};
