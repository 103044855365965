import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Theme from "@/plugins/theme";
import VueTheMask from "vue-the-mask";
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import pt_BR from "vee-validate/dist/locale/pt_BR";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import Notifications from "vue-notification";
import Unicon from "vue-unicons";
import { uniWhatsapp, uniPhone, uniShop } from "vue-unicons/src/icons";
import money from "v-money";
import vSelect from "vue-select";
import vueDebounce from "vue-debounce";
import infiniteScroll from "vue-infinite-scroll";
import VueGoogleCharts from "vue-google-charts";
import VueApexCharts from "vue-apexcharts";
import VueToast from "vue-toast-notification";
import * as VueGoogleMaps from "vue2-google-maps";
import VueMeta from "vue-meta";
import VueExcelXlsx from "vue-excel-xlsx";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);
Vue.use(VueExcelXlsx);
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});
// Import any of available themes
import "vue-toast-notification/dist/theme-default.css";
import "leaflet/dist/leaflet.css";

Unicon.add([uniWhatsapp, uniPhone, uniShop]);

// install rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize("pt_BR", pt_BR);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDj3k_kx9Uc8Q5UGSt_5U8BSkI4IdYPUew",
    libraries: "places"
  }
});
Vue.use(VueApexCharts);
Vue.use(Unicon);
Vue.use(Theme);
Vue.use(VueTheMask);
Vue.use(vueDebounce);
Vue.use(infiniteScroll);
Vue.use(VueGoogleCharts);
Vue.use(VueToast);
// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(Notifications);
Vue.use(money, {
  decimal: ",",
  thousands: ".",
  prefix: "R$ ",
  precision: 2,
  masked: false
});

Vue.component("v-select", vSelect);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

Vue.component("apexchart", VueApexCharts);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
