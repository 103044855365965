import Vue from "vue";
import Vuex from "vuex";
import { Auth } from "../services/oauth";
import Service from "../services";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentUser:
      localStorage.getItem("user-payload") != null
        ? JSON.parse(localStorage.getItem("user"))
        : null,
    metaData:
      localStorage.getItem("user-meta") != null
        ? JSON.parse(localStorage.getItem("user-meta"))
        : null,
    showError: false
  },
  getters: {
    currentUser: state => state.currentUser,
    currentMeta: state => state.metaData,
    showError: state => state.showError
  },
  mutations: {
    setUser(state, payload) {
      state.currentUser = payload;
    },
    setMeta(state, payload) {
      state.metaData = payload;
    },
    setLogout(state) {
      state.currentUser = null;
      state.metaData = null;
    },
    showError(state, payload) {
      state.showError = payload.show;
    }
  },
  actions: {
    async login({ commit }, payload) {
      await Auth.login(payload.email, payload.password)
        .then(async data => {
          const user = {
            id: data.user.id,
            name: data.user.name,
            role: data.user.role
          };
          localStorage.setItem("user", JSON.stringify(user));
          commit("setUser", user);
          const service = new Service();
          if (user.role == "city") {
            await service.get(`city/${data.user.city_id}`).then(res => {
              const meta = {
                type: "city",
                id: res.id,
                name: res.name
              };
              localStorage.setItem("user-meta", JSON.stringify(meta));
              commit("setMeta", meta);
            });
          } else if (user.role == "store") {
            await service.get(`store/my`).then(res => {
              const meta = {
                type: "store",
                stores: res.stores
              };
              localStorage.setItem("user-meta", JSON.stringify(meta));
              commit("setMeta", meta);
            });
          } else {
            localStorage.setItem(
              "user-meta",
              JSON.stringify({ type: "admin" })
            );
            commit("setMeta", { type: "admin" });
          }
        })
        .catch(() => {
          Vue.$toast.open({
            position: "top",
            message: "Não foi possível efetuar o login",
            type: "error"
          });
          commit("showError", { show: true });
        });
    },
    logOut({ commit }) {
      localStorage.removeItem("user");
      localStorage.removeItem("user-payload");
      localStorage.removeItem("user-meta");
      localStorage.removeItem("token");
      commit("setLogout");
    }
  },
  modules: {}
});
