<template>
  <section class="sidebar-page">
    <div class="sidebar-page-item" :class="{ teste: $sidebar.showSidebar }">
      <div v-for="(item, index) in items" :key="index">
        <div
          v-on:click="select(item.route), $sidebar.displaySidebar(false)"
          type="button"
          class="side-page-item-1 button d-flex flex-column align-items-center"
        >
          <home v-if="index == 0" :height="24" :width="24" />
          <info v-if="index == 1" :height="24" :width="24" />
          <b-icon-link45deg
            style="width: 24px; height: 24px"
            v-if="index == 2"
          />
          <user v-if="index == 3" :height="24" :width="24" />
          <span v-if="index == 3">{{
            isLogged == true ? "Painel" : "Acessar"
          }}</span>
          <span v-else>{{ item.label }}</span>
        </div>
      </div>
      <div v-if="$sidebar.showSidebar && isLogged" @click="logoff">
        <div
          class="side-page-item-1 button d-flex flex-column align-items-center"
        >
          <img height="30px" :src="require('../assets/icon/logout.svg')" />
          <span style="color: #fff; padding-right: 10px; padding-top: 5px"
            >Sair</span
          >
        </div>
      </div>

      <div
        class="mt-3 d-flex flex-column justify-content-center"
        style="background: #132644"
      >
        <span style="color: #aaa; font-size: 12px; text-align: center">
          Versão
          <br />1.2.45
        </span>
      </div>
    </div>
  </section>
</template>

<script>
import Home from "../assets/icon/home";
import Info from "../assets/icon/info";
import User from "../assets/icon/user";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    Home,
    Info,
    User
  },
  data() {
    return {
      items: [
        { label: "Home", icon: "home", route: "/home" },
        { label: "Informações", icon: "info", route: "/informacoes" },
        { label: "Utilidades", icon: "fone", route: "/utilidades" },
        {
          label: "Acessar",
          icon: "user",
          route: "/acesso"
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
    isLogged() {
      return this.currentUser != null;
    }
  },
  watch: {
    isLogged: function() {
      if (this.isLogged) this.items[3].label = "Painel";
      else this.items[3].label = "Acessar";
    }
  },
  methods: {
    ...mapActions(["logOut"]),
    logoff() {
      this.logOut();
      this.$router.go();
    },
    select(route) {
      this.$router.push(route);
    }
  }
};
</script>
<style scoped></style>
