<template>
  <svg
    :width="this.width"
    :height="this.height"
    viewBox="0 0 48 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42 46H28V33H20V46H6V25C6 24.448 6.447 24 7 24C7.553 24 8 24.448 8 25V44H18V31H30V44H40V26C40 25.448 40.447 25 41 25C41.553 25 42 25.448 42 26V46Z"
      :fill="this.iconColor"
    />
    <path
      d="M47 25C46.751 25 46.503 24.908 46.309 24.723L24 3.384L1.69099 24.723C1.29199 25.104 0.658995 25.091 0.276995 24.692C-0.105005 24.293 -0.0900051 23.66 0.307995 23.278L24 0.615997L47.691 23.277C48.089 23.659 48.104 24.292 47.722 24.691C47.526 24.896 47.264 25 47 25Z"
      :fill="this.iconColor"
    />
    <path
      d="M39 13C38.447 13 38 12.552 38 12V6H32C31.447 6 31 5.552 31 5C31 4.448 31.447 4 32 4H40V12C40 12.552 39.553 13 39 13Z"
      :fill="this.iconColor"
    />
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "box"
    },
    width: {
      type: [Number, String],
      default: 50
    },
    height: {
      type: [Number, String],
      default: 50
    },
    iconColor: {
      type: String,
      default: "white"
    }
  }
};
</script>
<style></style>
