<template>
  <div class="layout">
    <navbar class="layout-navbar" />
    <sidebar></sidebar>
    <div class="layout-content">
      <transition name="moveUp">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";

export default {
  components: {
    Navbar,
    Sidebar
  }
};
</script>
<style>
.moveUp-enter-active {
  animation: fadeIn 1s ease-in;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
</style>
