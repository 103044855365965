import JwtDecode from "jwt-decode";
import Service from "./index";

class OAuth {
  login(username, password) {
    const body = {
      email: username,
      password: password
    };
    return new Promise((resolve, reject) => {
      const service = new Service();
      service
        .post("/user/auth", body)
        .then(res => {
          this.processToken(res);
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  processToken(res) {
    const access_token = res.token;

    const payload = JwtDecode(access_token);

    this.setUser(JSON.stringify(payload));
    this.setToken(access_token);

    return { access_token, payload };
  }
  setToken(token) {
    localStorage.setItem("token", token);
  }
  setUser(payload) {
    localStorage.setItem("user-payload", payload);
  }

  getToken() {
    return localStorage.getItem("token");
  }
  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("user-payload");
  }
  isTokenExpired(jwtToken) {
    const token = JwtDecode(jwtToken);
    return token.exp - 5 < Date.now() / 1586174263;
  }
}
export const Auth = new OAuth();
